<template>
    <ds-organism-list-magazine-articles
        :heading="heading"
        :items="items"
        :link-label="linkLabel"
        :link-url="linkUrl"
        :list-style="listStyle"
        :enable-heading-divider="enableHeadingDivider"
        :enable-item-divider="enableItemDivider"
        :background="background"
        :title-color="titleColor"
        :divider-color="dividerColor"
        :link-color="linkColor"
        :link-hover-color="linkHoverColor"
        :link-active-color="linkActiveColor"
        :card-style="cardStyle"
    >
        <template #heading="{ classes }">
            <WatsonAtomDynamicText
                v-if="heading"
                :class="classes"
                :text="heading"
                :typography="typography.heading"
            />
        </template>

        <template #itemImage="{ image, classes }">
            <div :class="classes + '-container'">
                <WatsonAtomImage
                    v-if="image?.url"
                    :class="classes"
                    :content="image"
                />

                <img
                    v-else-if="page.props.brand.logo"
                    :class="[
                        classes,
                        classes + '--fallback'
                    ]"
                    :src="page.props.brand.logo"
                >
            </div>
        </template>

        <template #itemTitle="{ title, classes }">
            <WatsonAtomDynamicText
                v-if="title"
                :class="classes"
                :text="title"
                :typography="typography.cardTitle"
            />
        </template>

        <template #itemDate="{ date, classes }">
            <p
                v-if="date"
                :class="[
                    classes,
                    'u-tiny-text u-regular'
                ]"
            >
                {{ date }}
            </p>
        </template>

        <template #link="{ classes }">
            <Link
                v-if="linkUrl && linkLabel"
                :href="linkUrl"
                target="_self"
                :class="classes"
            >
                {{ linkLabel }}
            </Link>
        </template>
    </ds-organism-list-magazine-articles>
</template>

<script setup>
import { usePage } from '@inertiajs/vue3';

import { DsOrganismListMagazineArticles } from 'design-system-component-library/marketplaces';

import WatsonAtomDynamicText from '@atoms/dynamic-text/watson-atom-dynamic-text.vue';
import WatsonAtomImage from '@atoms/image/watson-atom-image.vue';

const page = usePage();

const props = defineProps({
    content: {
        type: Object,
        required: true,
    },
    heading: {
        type: String,
        required: false,
        default: null,
    },
    enableHeadingDivider: {
        type: Boolean,
        required: false,
        default: false,
    },
    items: {
        type: Array,
        required: true,
    },
    enableItemDivider: {
        type: Boolean,
        required: false,
        default: false,
    },
    linkUrl: {
        type: String,
        required: false,
        default: null,
    },
    linkLabel: {
        type: String,
        required: false,
        default: null,
    },
    listStyle: {
        type: String,
        required: false,
        default: 'horizontal',
    },
    background: {
        type: String,
        required: false,
        default: null,
    },
    titleColor: {
        type: String,
        required: false,
        default: null,
    },
    dividerColor: {
        type: String,
        required: false,
        default: null,
    },
    linkColor: {
        type: String,
        required: false,
        default: null,
    },
    linkHoverColor: {
        type: String,
        required: false,
        default: null,
    },
    linkActiveColor: {
        type: String,
        required: false,
        default: null,
    },
    cardStyle: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    typography: {
        type: Object,
        required: true,
        default: () => ({}),
    },
});
</script>

<style lang="scss">
@use 'watson-theme-list-magazine-articles';
</style>
